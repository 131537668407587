// Icons from here https://fonts.google.com/icons
export const ICONS_LIST = [
  { id: `fork_spoon`, icon: `fork_spoon`, label: `Fork and Spoon` },
  { id: `favorite`, icon: `favorite`, label: `Heart` },
  { id: `park`, icon: `park`, label: `Tree` },
  { id: `vaccines`, icon: `vaccines`, label: `Syringe` },
  { id: `dining`, icon: `dining`, label: `Dining` },
  { id: `flatware`, icon: `flatware`, label: `Utensils` },
  { id: `local_hospital`, icon: `local_hospital`, label: `Medical` },
  { id: `lunch_dining`, icon: `lunch_dining`, label: `Lunch` },
  { id: `nutrition`, icon: `nutrition`, label: `Nutrition` }, 
  
];
