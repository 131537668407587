import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BaseButton } from "../../../../../shared/BaseButton";
import { FormProvider, useForm } from "react-hook-form";
import { ConsultantFieldsDefaults } from "./formConstants";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useCreateEditVisitPlanMutation,
  useGetFacilityByIdQuery,
  useGetLoggedRecordQuery,
} from "../../../../../../store/apis/ConsultantApis";
import { useSelector } from "react-redux";
import { WarningText } from "../../../../../shared/WarningText";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import { VisitDetailsForm } from "../../shared/visitdetailsForm";
import isUndefined from "lodash/isUndefined";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";

import { BackButton } from "components/shared/BackButton";
import HeaderBox from "components/consultant/shared/HeaderBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import dayjs from "dayjs";
import { useGetFacilitiesByDietitianQuery } from "../../../../../../store/apis/DietitianAPis";
// import useMsalAccount from "../../../../../../utils/useMsalAccount";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";

export const ConsultantDetails = () => {
  // stats collection and constants
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isErrorText, setIsErrorText] = useState(false);
  const [errorText, setErrorText] = useState("");
  const VISIT_PLANE_ID_QUERY = "visitPlanId";
  const ISEDIT_QUERY = "isEdit";
  const [searchParams] = useSearchParams();
  const currentPlaneId = searchParams.get(VISIT_PLANE_ID_QUERY);
  const isEdit = searchParams.get(ISEDIT_QUERY);
  const [isChange, setIsChange] = useState(false);
  // const { userId } = useMsalAccount();
  const [userFacilityList, setUserFacilityList] = useState([]);

  // const visitFacilityId = useSelector(
  //   (state) => state.facilityId.visitFacilityId
  // );
  const navigate = useNavigate();

  const dietitianId = useSelector((state) => state.dietitionId.dietition);

  const location = useLocation();
  const planDate = location.state?.dateOfConsultant ?? null;

  // destructing of useform

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: ConsultantFieldsDefaults,
  });
  const { handleSubmit, watch } = methods;

  // variable to store data of inputs, used somewhere for checks
  const isFutureNA = watch("nextConsultNA");
  const communityId = watch("facilityId");
  // Api Section
  // APi for Logged Data to show

  const { data: currentFacility } = useGetFacilityByIdQuery(communityId, {
    skip: !communityId || typeof communityId === "object",
  });

  const {
    data: loggedData,
    isError,
    isSuccess: isLoggedSuccess,
  } = useGetLoggedRecordQuery(currentPlaneId, {
    refetchOnMountOrArgChange: true,
  });

  const isGetError = isError && !isLoggedSuccess;

  // Mutation
  // Api to store visitDetail data and create VisitPlan
  const [createVisitPlan, { isSuccess, isLoading: isCreateVisitLoading }] =
    useCreateEditVisitPlanMutation();

  const { data: userFacilities, isLoading: facilityLoading } =
    useGetFacilitiesByDietitianQuery(dietitianId, {
    skip: !dietitianId,
  });

  useEffect(() => {
    let facilityList = [];
    if (userFacilities) {
      userFacilities.map((facility) => {
        facilityList.push({
          id: facility.facilityId,
          label: facility.facilityName,
        });
      });
    }

    facilityList.sort((a, b) => a.label.localeCompare(b.label));

    setUserFacilityList(facilityList);
  }, [userFacilities]);

  // used for navigation when isSuccess is true
  useEffect(() => {
    if (isSuccess) {
      planDate
        ? navigate(
            `/Consultant/MyRosters/VisitDetails?visitPlanId=${currentPlaneId}`
          )
        : navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isErrorText && loggedData) {
      setIsErrorText(false);
    } else if (isGetError) {
      setIsErrorText(true);
      setErrorText(`You must have hours records added to save visit report`);
    }
  }, [loggedData]);

  // function used to create or update VisitPlan
  const onSave = (data) => {
    setIsChange(false);
    const newData = {
      dateOfVisit: data?.dateOfVisit,
      nextConsult: isFutureNA ? null : data?.nextConsult,
      facilityId: data?.facilityId, //visitFacilityId?.facilityId,
      isFinal: data?.isFinal,
      substituteRD: data?.substituteRD,
      nextVisitNotes: isFutureNA ? data.nextVisitNotes : "",
      nextConsultNA: isFutureNA,
      visitPlanId: currentPlaneId,
      dietitianId: dietitianId,
      scheduled: data?.scheduled,
      community: `${data?.facilityId}|0`,
      planDate: planDate
        ? dayjs(planDate).format("YYYY-MM-DD")
        : data.dateOfVisit,
    };
    if (isUndefined(loggedData) || isGetError) {
      setIsErrorText(true);
      setErrorText(`You must have hours records added to save visit report`);
    } else {
      setIsErrorText(false);
      createVisitPlan(newData);
    }
  };

  const deleteVisitPlan = (event) => {
    event.preventDefault();
    setOpenDeleteModal(true);
  };
  const ScreenNames = [
    {
      id: 1,
      screenName: false,
      bgColor: theme.palette.primary.main,
      fontWeight: "600",
      color: DMStyles.White,
      marginBottom: "5px",
    },
    {
      id: 2,
      screenName: "Visit Details",
      bgColor: theme.palette.gray.darkGray,
      fontWeight: "500",
      color: DMStyles.White,
      marginBottom: "0px",
    },
  ];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box>
          <BackButton iconDisabled={true} />
        </Box>
        <HeaderBox
          visitPlanId={currentPlaneId}
          ScreensArray={ScreenNames}
          currentFacility={currentFacility}
          dateofConsult={planDate}
        />

        <Box
          sx={{
            width: "60%",
          }}
        >
          {isErrorText ? (
            <WarningText
              sx={{
                marginTop: "-50px !important",
                marginLeft: "0px !important",
              }}
              width={"80%"}
              color={"red"}
              text={errorText}
            />
          ) : null}
        </Box>

        <FlexBox justifyContent={"space-between"}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: 700,
                marginBottom: "5px",
              }}
            >
              Change Community
            </div>
            <FormProvider {...methods}>
              <BaseAutoComplete
                options={userFacilityList ? userFacilityList : []}
                defaultOptions={[
                  {
                    id: currentFacility?.id,
                    label: currentFacility?.name,
                  },
                ]}
                loading={facilityLoading}
                formSelectProps={{
                  props: {
                    label: "Community",
                    id: "facilityId",
                    name: "facilityId",
                  },
                }}
              />
            </FormProvider>
          </Box>
          <Box>
            <BaseSubmitButton
              isSubmitting={isCreateVisitLoading}
              text="Proceed To Reports"
              onClick={handleSubmit(onSave)}
            />
            {isEdit && (
              <BaseButton
                marginLeft={"10px"}
                text="Cancel"
                colors="white"
                onClick={() => navigate(-1)}
              />
            )}
          </Box>
        </FlexBox>
        <Box>
          <VisitDetailsForm
            methods={methods}
            isEdit={isEdit}
            isFutureNA={isFutureNA}
            hasChange={isChange}
            setHasChange={setIsChange}
          />
        </Box>
        <Box>
          <BaseButton
            variant={"outlined"}
            colors="error"
            isNotLink={true}
            text={"Delete Visit"}
            onClick={deleteVisitPlan}
          />
        </Box>

        {openDeleteModal ? (
          <DeleteModal
            To={"/Consultant/MyRosters"}
            id={currentPlaneId}
            apiPath={"deleteVisitPlane"}
            entityName={"This Visit"}
            open={openDeleteModal}
            close={() => {
              setOpenDeleteModal(false);
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
