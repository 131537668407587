import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";

import { REQUIRED_ERROR } from "utils/errorMessages";
import {
  baseNameAlphaNumericSpcialCharacterAltValidation,
  emailValidation,
  passwordValidation,
} from "utils/ValidationRegex";

export const AccessPermissionFields = {
  MenuCheckBoxes: [
    {
      props: {
        label: "Print Diet Spreadsheet",
        name: "allowPrintDietSpreadsheet",
      },
    },
    {
      props: {
        label: "Print Shopping List",
        name: "allowPrintShoppingList",
      },
    },
    {
      props: {
        label: "Print Ingredients",
        name: "allowPrintIngredients",
      },
    },
    {
      props: {
        label: "Print Production Sheet",
        name: "allowPrintProductionSheet",
      },
    },
    {
      props: {
        label: "Print Nutritional Analysis",
        name: "allowPrintNutritionalAnalysis",
      },
    },
    {
      props: {
        label: "Print Posted Menu",
        name: "allowPrintPostedMenus",
      },
    },
    {
      props: {
        label: "Print Week at a Glance",
        name: "allowPrintWeekAtAglance",
      },
    },
    {
      props: {
        label: "Print Recipes",
        name: "allowPrintRecipes",
      },
    },
    {
      props: {
        label: "Print Selection Sheets",
        name: "allowPrintSelectionSheets",
      },
    },
    {
      props: {
        label: "Editable Shopping List",
        name: "allowEditableShoppingList",
      },
    },
    {
      props: {
        label: "Show Vendor Pricing",
        name: "showVendorPricing",
      },
    },
    {
      props: {
        label: "Include Calories/Protein on WAAG",
        name: "allowIncludeCaloriesOnWaag",
      },
    },
  ],
  SecurityCheckBoxes: [
    {
      props: {
        label: "Contracted Consultant",
        name: "htiemployee",
      },
    },
    {
      props: {
        label: "1099 Contractor",
        name: "is1099Contractor",
      },
    },
    {
      props: {
        label: "Advanced User",
        name: "advancedUser",
      },
    },
    {
      props: {
        label: "Graves User",
        name: "gravesUser",
      },
    },
    {
      props: {
        label: "Show Front Page Documents",
        name: "showFrontPageDocuments",
      },
    },
    {
      props: {
        label: "User Can Share Menus",
        name: "allowShareMenus",
      },
    },
    {
      props: {
        label: "User Can Share Recipes",
        name: "allowShareRecipes",
      },
    },
    {
      props: {
        label: "Menu Signing",
        name: "canSignMenus",
      },
      validationProsp: {},
    },
    {
      props: {
        label: "Archived",
        name: "archived",
      },
    },
  ],
};

export const userInformation = [
  {
    props: {
      label: "Username",
      id: "userName",
      name: "userName",
      helperText:
        "Username is the Email Address that the user enters on the login screen. This will also be the Email Address that password reset emails will be sent.",
    },
    validationProps: {
      required: REQUIRED_ERROR("Username"),
      pattern: {
        value: emailValidation,
        message: "Username must be a valid email address.",
      },
    },

    size: 6,
  },
  {
    props: {
      label: "Password",
      type: "password",
      id: "password",
      name: "password",
    },
    validationProps: {
      required: REQUIRED_ERROR("Password"),
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters long.",
      },
      maxLength: {
        value: 30,
        message: "Password should be less than 30 characters.",
      },
      pattern: {
        value: passwordValidation,
        message:
          "Password must contain at least 2 of the following: upper case, lower case, numeric, symbol.",
      },
    },

    size: 6,
  },
  {
    props: {
      label: "First Name",
      id: "firstName",
      name: "firstName",
    },
    validationProps: {
      required: REQUIRED_ERROR("First Name"),
      pattern: {
        value: baseNameAlphaNumericSpcialCharacterAltValidation,
        message: "First Name is not valid.",
      },
    },

    size: 6,
  },

  {
    props: {
      label: "Last Name",
      id: "lastName",
      name: "lastName",
    },
    validationProps: {
      required: REQUIRED_ERROR("Last Name"),
      pattern: {
        value: baseNameAlphaNumericSpcialCharacterAltValidation,
        message: "Last Name is not valid.",
      },
    },

    size: 6,
  },
  {
    props: {
      name: "facilityId",
      id: "facilityId",
      label: "Community",
    },
    validationProps: {},
    size: 6,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getFacilitySelectBox",
  },
];
export const recipeSettingForm = [
  {
    props: {
      label: "Custom Servings",
      id: "customServings1",
      type: "number",
      name: "customServings1",
    },
    size: 4,
  },
  {
    props: {
      label: "Custom Servings",
      id: "customServings2",
      type: "number",
      name: "customServings2",
    },
    size: 4,
  },
  {
    props: {
      label: "Custom Servings",
      id: "customServings3",
      type: "number",
      name: "customServings3",
    },
    size: 4,
  },
  {
    props: {
      label: "Custom Theme",
      id: "customThemeName",
      name: "customThemeName",
    },
    size: 4,
  },
  {
    props: {
      label: "Sharing Group",
      id: "recipeSharingGroupId",
      name: "recipeSharingGroupId",
    },
    size: 4,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRecipeSharingGroups",
  },
];
export const userMenuAccess = [
  {
    props: {
      text: "Menu/Recipe Access",
      fontSize: 25,
    },
    type: FORM_TYPES.Text,
    size: 12,
  },
  {
    props: {
      name: "allowMenuRecipe",
      id: "allowMenuRecipe",
      label: "Use Menu/Recipe Screens",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "menuExpirationDate",
      id: "menuExpirationDate",
      label: "Expiration Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 8,
  },
  {
    props: {
      text: "MealCard Access",
      fontSize: 25,
    },
    type: FORM_TYPES.Text,
    size: 12,
  },
  {
    props: {
      name: "allowTrayCard",
      id: "allowTrayCard",
      label: "Use MealCard Functionality",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "traycardExpirationDate",
      id: "traycardExpirationDate",
      label: "Expiration Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 8,
  },
  {
    props: {
      text: "TableSide Access",
      fontSize: 25,
    },
    type: FORM_TYPES.Text,
    size: 12,
  },
  {
    props: {
      name: "allowTableSideOrdering",
      id: "allowTableSideOrdering",
      label: "TableSide Ordering",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "allowTableSideSetup",
      id: "allowTableSideSetup",
      label: "TableSide Setup (Incl. Print Tickets)",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "tableSideExpirationDate",
      id: "tableSideExpirationDate",
      label: "Expiration Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 8,
  },
  {
    props: {
      text: "Consultant Access",
      fontSize: 25,
    },
    type: FORM_TYPES.Text,
    size: 12,
  },
  {
    props: {
      name: "dietitian",
      id: "dietitian",
      label: "Use Consultant Screens",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "dietitianExpirationDate",
      id: "dietitianExpirationDate",
      label: "Expiration Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 8,
  },
];

export const securityFields = [
  {
    props: {
      text: "Security",
      fontSize: 25,
    },
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "administrator",
      id: "administrator",
      label: "Administrator",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      text: "Checking the 'Administrator' box will give user access to all communities and data in Dining Manager. Make sure this is desired before checking the 'Administrator' box.",
      fontSize: 12,
      color: "#A80000",
    },
    type: FORM_TYPES.Text,
  },

  {
    props: {
      label: "Contracted Consultant",
      name: "htiemployee",
      id: "htiemployee",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "1099 Contractor",
      name: "is1099Contractor",
      id: "is1099Contractor",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Advanced User",
      name: "advancedUser",
      id: "advancedUser",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Graves User",
      name: "gravesUser",
      id: "gravesUser",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Show Front Page Documents",
      name: "showFrontPageDocuments",
      id: "showFrontPageDocuments",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "User Can Share Menus",
      name: "allowShareMenus",
      id: "allowShareMenus",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "User Can Share Recipes",
      name: "allowShareRecipes",

      id: "allowShareRecipes",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Menu Signing",
      name: "canSignMenus",
      id: "canSignMenus",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Archived",
      name: "archived",
      id: "archived",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
];
